// StatusBars.ts
import Phaser from "phaser";

export class StatusBars {
    private scene: Phaser.Scene;
    private healthBar: Phaser.GameObjects.Graphics;
    private healthBarBackground: Phaser.GameObjects.Graphics;
    private staminaBar: Phaser.GameObjects.Graphics;
    private staminaBarBackground: Phaser.GameObjects.Graphics;
    private experienceBar: Phaser.GameObjects.Graphics;
    private experienceBarBackground: Phaser.GameObjects.Graphics;

    private totalHealthValue: number;
    private totalStaminaValue: number;
    private totalExperienceValue: number;

    constructor(scene: Phaser.Scene, x: number, y: number) {
        this.scene = scene;

        this.healthBar = this.scene.add.graphics();
        this.healthBarBackground = this.scene.add.graphics();
        this.staminaBar = this.scene.add.graphics();
        this.staminaBarBackground = this.scene.add.graphics();
        this.experienceBar = this.scene.add.graphics();
        this.experienceBarBackground = this.scene.add.graphics();

        this.totalHealthValue = 50;
        this.totalStaminaValue = 50;
        this.totalExperienceValue = 50;

        this.createBars(x, y);
    }

    private createBars(x: number, y: number) {
        console.log("CREATE STATUS BARS ")
        const createBar = (border: Phaser.GameObjects.Graphics, background: Phaser.GameObjects.Graphics, fill: Phaser.GameObjects.Graphics,
            x: number, y: number, value: number, fillColor: number) => {
            border.fillStyle(0x111b28, 1).fillRect(-25, y - 1, value + 2, 5); // Border
            background.fillStyle(0x222222, 1).fillRect(-26, y - 1, value + 2, 5); // Background
            fill.fillStyle(fillColor, 1).fillRect(x, y, value, 3); // Fill
        };

        createBar(this.healthBar, this.healthBarBackground, this.healthBar, x, y - 4, this.totalHealthValue, 0x32cd32);
        createBar(this.staminaBar, this.staminaBarBackground, this.staminaBar, x, y, this.totalStaminaValue, 0x778afd);
        createBar(this.experienceBar, this.experienceBarBackground, this.experienceBar, x, y + 4, this.totalExperienceValue, 0xFF9900);
    }

    public updateHealthBar(currentHealth: number, maxHealth: number) {
        this.healthBar.clear();
        let temp = (currentHealth / maxHealth) * 50;
        if (temp > 50) temp = 50;
        this.healthBar.fillStyle(0x32cd32, 1);
        this.healthBar.fillRect(-25, -4, temp, 3);
    }

    public updateStaminaBar(currentStamina: number, maxStamina: number) {
        this.staminaBar.clear();
        let temp = (currentStamina / maxStamina) * 50;
        if (temp > 50) temp = 50;
        this.staminaBar.fillStyle(0x778afd, 1);
        this.staminaBar.fillRect(-25, 0, temp, 3);
    }

    public updateExperienceBar(currentXP: number, xpForNextLevel: number) {
        this.experienceBar.clear();
        let temp = (currentXP / xpForNextLevel) * 50;
        if (temp > 50) temp = 50;
        this.experienceBar.fillStyle(0xFF9900, 1);
        this.experienceBar.fillRect(-25, 4, temp, 3);
    }

    public getBars() {
        return {
            healthBar: this.healthBar,
            healthBarBackground: this.healthBarBackground,
            staminaBar: this.staminaBar,
            staminaBarBackground: this.staminaBarBackground,
            experienceBar: this.experienceBar,
            experienceBarBackground: this.experienceBarBackground,
        };
    }
}