/* eslint @typescript-eslint/no-unused-vars: off */
/* eslint @typescript-eslint/no-explicit-any: off */

import JoystickController from 'joystick-controller'
import { checkIpad } from '../../utils/systemInfo'

class Joystick {
  public static outerRadius: number = 15
  public static innerRadius: number = 10
  public static isTouched: boolean = false
  private static staticJoystick: any
  private static buttons: any
  static buttonContainer: Phaser.GameObjects.Container
  public static MoveController(orientation: string, listener: any) {
    const isIpad = checkIpad()
    // this.outerRadius = isIpad ? 40 : 30
    // this.innerRadius = isIpad ? 30 : 20
    this.staticJoystick = new JoystickController(
      {
        maxRange: 50,
        level: 10,
        radius: Joystick.outerRadius,
        joystickRadius: Joystick.innerRadius,
        opacity: 1,
        leftToRight: false,
        bottomToUp: true,
        containerClass: 'joystick-container',
        controllerClass: 'joystick-controller',
        joystickClass: 'joystick',
        distortion: true,
        dynamicPosition: true,
        mouseClickButton: 'MIDDLE',
        hideContextMenu: false,
        zIndex: 1,
      },
      (data: any) => {
        Joystick.isTouched = true
        listener(data)
      },
    )
  }
  public static ShootController(scene: Phaser.Scene, orientation: string, listener: any) {
    const width = scene.sys.game.canvas.clientWidth;
    const height = scene.sys.game.canvas.clientHeight;
    const isIpad = checkIpad();

    this.buttons = [];

    // Create a container for the buttons
    const buttonContainer = scene.add.container(0, 0);

    // Punch button (left)
    const punch = scene.add.image(0, 0, 'joyA').setOrigin(0.5).setScrollFactor(0);
    punch.setScale(50 / punch.displayHeight).setAlpha(0.8);
    punch.setInteractive();
    punch.addListener(Phaser.Input.Events.POINTER_DOWN, () => {
      listener({ punch: true, kick: false, drink: false, dummy: false });
    });

    // Drink button (top)
    const item = scene.add.image(0, 0, 'brew-can').setOrigin(0.5).setScrollFactor(0);
    item.setScale(20 / item.displayHeight).setAlpha(0.8);
    item.setInteractive();
    item.addListener(Phaser.Input.Events.POINTER_DOWN, () => {
      listener({ punch: false, item: true, kick: false, dummy: false });
    });
    // Drink button (top)
    // const drink = scene.add.image(0, 0, 'brew-can').setOrigin(0.5).setScrollFactor(0);
    // drink.setScale(20 / drink.displayHeight).setAlpha(0.8);
    // drink.setInteractive();
    // drink.addListener(Phaser.Input.Events.POINTER_DOWN, () => {
    //   listener({ punch: false, drink: true, kick: false, dummy: false });
    // });
    // Kick button (right)
    const kick = scene.add.image(0, 0, 'joyB').setOrigin(0.5).setScrollFactor(0);
    kick.setScale(50 / kick.displayHeight).setAlpha(0.8);
    kick.setInteractive();
    kick.addListener(Phaser.Input.Events.POINTER_DOWN, () => {
      listener({ punch: false, kick: true, item: false, dummy: false });
    });

    // Dummy button (bottom)
    const dummy = scene.add.image(0, 0, 'joyC').setOrigin(0.5).setScrollFactor(0);
    dummy.setScale(50 / dummy.displayHeight).setAlpha(0.8);
    dummy.setInteractive();
    dummy.addListener(Phaser.Input.Events.POINTER_DOWN, () => {
      listener({ punch: false, item: false, kick: false, dummy: true });
    });

    // Add buttons to the container
    buttonContainer.add([punch, item, kick, dummy]);

    // Position buttons in a diamond formation
    const spacing = 40; // Distance between buttons

    punch.setPosition(-spacing, 0); // Left
    item.setPosition(0, -spacing); // Top
    kick.setPosition(spacing, 0);  // Right
    dummy.setPosition(0, spacing); // Bottom

    this.buttons.push(punch, item, kick, dummy);

    // Position the container
    const containerX = width * 0.80; // Adjust as needed
    const containerY = height * 0.85; // Adjust as needed
    buttonContainer.setPosition(containerX, containerY);

    // Store the container for future use (e.g., moving or destroying)
    this.buttonContainer = buttonContainer;
  }

  public static destroy() {
    if (this.staticJoystick) this.staticJoystick!.destroy()
    if (this.buttons) {
      this.buttons.forEach((btn: any) => {
        if (btn) btn.destroy(true)
      })
    }
  }

  public static toggleItemVisibility(brewAvailable: boolean) {
    // console.log("debug -- toggleItemVisibility ", this.buttons)
    if (this.buttons) {
      //Add brew image to button
      // this.buttons[2].setVisible(brewAvailable)
      this.buttons[1].setVisible(brewAvailable);

    }
  }
}

export default Joystick
